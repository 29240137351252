import useSWR, { mutate } from 'swr';
import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import { Variant } from '@Types/product/Variant';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import { Order } from '@Types/cart/Order';

export type CartDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
};
export type OrderDetails = {
  orderNumber: string;
  sourceCode?: string;
};

export const cartItems = () => {
  return useSWR('/action/cart/getCart', fetchApiHub, revalidateOptions);
};

export const checkout = async () => {
  const res = await fetchApiHub('/action/cart/checkout', {
    method: 'POST',
  });
  await mutate('/action/cart/getCart', res);
  await mutate('/action/cart/recentOrder', res);
};

export const orderHistory = async () => {
  return await fetchApiHub('/action/cart/getOrders');
};

export const getRecentOrder = () => {
  return useSWR('/action/cart/recentOrder', fetchApiHub, revalidateOptions);
};

export const orderDetails = async (orderId: string) => {
  const orders = await fetchApiHub('/action/cart/getOrders');
  return await orders.find((order) => order?.orderId === orderId);
};

export const ordersByMonths = async (months: number) => {
  return await fetchApiHub(
    '/action/cart/getOrdersByMonths',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    { months },
  );
};

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getShippingMethods');
};

export const addItem = async (variant: Variant, quantity: number) => {
  const payload = {
    variant: {
      sku: variant.sku,
      count: quantity,
    },
  };

  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  );

  await mutate('/action/cart/getCart', res);
  return res;
};
export const addMultipleItems = async (selectedItems: Array<{ variant: Variant; quantity: number }>) => {
  if (selectedItems && selectedItems?.length > 0) {
    const lineItems = [];
    selectedItems?.map((item: { variant: Variant; quantity: number }) => {
      const lineItemObj = {
        variant: {
          sku: item?.variant.sku || undefined,
          price: undefined,
        },
        count: +item?.quantity || 1,
      };
      lineItems?.push(lineItemObj);
    });
    const payload = { lineItems };
    const res = await fetchApiHub(
      '/action/cart/addMultipleItemsToCart',
      {
        method: 'POST',
      },
      payload,
    );

    await mutate('/action/cart/getCart', res);
  }
};
export const removeItem = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  };

  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  await mutate('/action/cart/getCart', res);
  return res;
};

export const updateItem = async (lineItemId: string, newQuantity: number) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  await mutate('/action/cart/getCart', res);
  return res;
};

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  await mutate('/action/cart/getCart', res);
  return res;
};

export const setSourceCode = async (payload: OrderDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/setSourceCode',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const setShippingMethod = async (shippingMethodId: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
  };

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  await mutate('/action/cart/getCart', res, { revalidate: false });
};

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  };
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  await mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  };
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  await mutate('/action/cart/getCart', res);
};

export const addShoppingList = async (shoppingListId: string) => {
  const payload = {
    shoppingListId: shoppingListId,
  };
  const res = await fetchApiHub('/action/cart/addShoppingList', { method: 'POST' }, payload);
  return await mutate('/action/cart/getCart', res);
};
