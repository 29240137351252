import { Account } from '@Types/account/Account';
import { mutate } from 'swr';
import { fetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub';
import { BusinessUnit, BusinessUnitPagedQueryResponse } from '@Types/BusinessUnit';

export interface BusinessUnitRegisterBody {
  email: string;
  password: string;
  name: string;
  phone: string;
  firstName: string;
  lastName: string;
}

export const registerBusinessUnit = async (account: BusinessUnitRegisterBody): Promise<Account> => {
  const res = await fetchApiHub('/action/businessunit/registerBusinessUnit', { method: 'POST' }, account);
  if (res?.id) {
    await mutate('/action/account/getAccount', res);
  }
  return res;
};
export const listBusinessUnits = async (): Promise<BusinessUnitPagedQueryResponse> => {
  const res = await fetchApiHub('/action/businessunit/listBusinessUnits', { method: 'POST' }, {});
  return res;
};
export const approveDisApproveBusinessUnits = async (
  businessUnitId: string,
  status: 'Active' | 'Inactive' | string,
): Promise<BusinessUnit> => {
  const payload = {
    businessUnitId,
    status,
  };
  const res = await fetchApiHub('/action/businessunit/approveDisApproveBusinessUnits', { method: 'POST' }, payload);
  return res;
};
export const deleteBusinessUnit = async (businessUnitId: string): Promise<BusinessUnit> => {
  const payload = {
    businessUnitId,
  };
  const res = await fetchApiHub('/action/businessunit/deleteBusinessUnit', { method: 'POST' }, payload);
  return res;
};
export const applyDiscountPercentage = async (
  customerId: string,
  percentageDiscount: Number,
  businessUnitId: string,
): Promise<any> => {
  const payload = {
    customerId,
    percentageDiscount,
    businessUnitId,
  };
  const res = await fetchApiHub('/action/businessunit/applyDiscountPercentage', { method: 'POST' }, payload);
  return res;
};
export const associateBusinessUnitToCustomer = async (customerId: string, businessUnitId: string): Promise<any> => {
  const payload = {
    customerId,
    businessUnitId,
  };
  const res = await fetchApiHub('/action/businessunit/associateBusinessUnitToCustomer', { method: 'POST' }, payload);
  return res;
};
