import React, { createContext, useState, useContext, ReactNode } from 'react';

type NotificationType = 'success' | 'error' | 'warning';

export interface Notification {
  message: string;
  type: NotificationType;
}

type NotificationContextType = {
  notification: Notification | null;
  showNotification: (message: string, type: NotificationType) => void;
  hideNotification: () => void;
  inlineNotification: Notification | null;
  showInlineNotification: (message: string, type: NotificationType) => void;
  hideInlineNotification: () => void;
  showMiniCartNotification: (message: string, type: NotificationType) => void;
  hideMiniCartNotification: () => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const [inlineNotification, setInlineNotification] = useState<Notification | null>(null);

  const showInlineNotification = (message: string, type: NotificationType) => {
    setInlineNotification({ message, type });
  };

  const hideInlineNotification = () => {
    setInlineNotification(null);
  };

  const showNotification = (message: string, type: NotificationType) => {
    setNotification({ message, type });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  const showMiniCartNotification = (message: string, type: NotificationType) => {
    setNotification({ message, type });
  };

  const hideMiniCartNotification = () => {
    setNotification(null);
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        inlineNotification,
        showNotification,
        hideNotification,
        showInlineNotification,
        hideInlineNotification,
        showMiniCartNotification,
        hideMiniCartNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  return context;
};
