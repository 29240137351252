import React, { useState, useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import TagManager from 'react-gtm-module';
import { InstantSearchSSRProvider } from 'react-instantsearch';
import Toaster from 'components/commercetools-ui/toaster';
import OrganizationRichText from 'components/seo/organizationRichText';
import WebpageRichText from 'components/seo/webpageRichText';
import WebsiteRichText from 'components/seo/websiteRichText';
import { CURRENT_PATH, PREVIOUS_PATH } from 'helpers/constants/localStorage';
import { ContinueShoppingContextProvider } from 'helpers/continueShoppingContext/continueShoppingContext';
import { NotificationProvider } from 'helpers/notification/notificationContext';
import { PageLoadContextProvider } from 'helpers/page-load-context/pageLoadContext';
import { FrontasticProvider } from 'frontastic';
import 'tailwindcss/tailwind.css';
import '../styles/app.css';
import '../styles/themes/default.css';
import '../styles/components/slider.css';
import '../styles/components/default-loader.css';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { algoliaConfig } from 'helpers/hooks/algoliaEvents/algoliaEvents';

function FrontasticStarter({ Component, pageProps, router }: AppProps) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (router.asPath !== '/not-found' && !router.asPath.includes('/products')) {
      localStorage.setItem('product404', null);
    }
    const currentPath = localStorage.getItem(CURRENT_PATH) ?? '';
    const actualCurrentPath = router.asPath?.split('?')[0] ?? router.asPath;
    const previousPath = localStorage.getItem(PREVIOUS_PATH);
    if (actualCurrentPath !== currentPath) {
      if (previousPath !== 'null') {
        localStorage.setItem(PREVIOUS_PATH, currentPath);
      } else {
        localStorage.setItem(PREVIOUS_PATH, '/');
      }
      localStorage.setItem(CURRENT_PATH, router.asPath?.split('?')[0] ?? router.asPath);
    }
  }, [router?.asPath]);

  useEffect(() => {
    getGoogleConfig();
    algoliaConfig();
  }, []);
  useEffect(() => {
    if (
      !router?.asPath.includes('categories') &&
      !router?.asPath.includes('products') &&
      !router?.asPath.includes('search')
    ) {
      localStorage.setItem('pageNo', '1');
    }
  }, [router.asPath]);

  const getGoogleConfig = async () => {
    const tmpGoogleConfig = await fetchApiHub(`/action/project/getGoogleConfig`, { method: 'POST' }, {}).then(
      (response) => {
        return response;
      },
    );
    TagManager?.initialize(tmpGoogleConfig?.gtm);
    setLoading(false);
  };

  return (
    <PageLoadContextProvider>
      <ContinueShoppingContextProvider>
        <FrontasticProvider>
          <NotificationProvider>
            <InstantSearchSSRProvider>
              <Component {...pageProps} />
            </InstantSearchSSRProvider>
            <Toaster />
          </NotificationProvider>
          {router?.asPath === '/' && (
            <>
              <OrganizationRichText />
              <WebsiteRichText />
            </>
          )}
          <WebpageRichText />
        </FrontasticProvider>
      </ContinueShoppingContextProvider>
    </PageLoadContextProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
