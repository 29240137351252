export const REMEMBER_ME = '__rememberMe';
export const PREF_DARK_MODE = '__pref_dark_mode';
export const IS_GUEST_USER = '__isGuestUser';
export const PAYMENT_TOKEN = '__cardToken';
export const CARD_NAME = '__cardName';
export const PAYMENT_PROFILE = '__paymentProfileId';
export const SEO_Variant = 'SEOVariant';
export const RECENTLY_VIEWED = 'Recently_Viewed';
export const CURRENTLY_SELECTED_VARIANT = 'currentlySelectedvariant';
export const PREVIOUS_PATH = 'previousPath';
export const CURRENT_PATH = 'currentPath';
export const WISHLIST_DATA = 'wishListData';
