import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import { Variant } from '@Types/product/Variant';

export const getProduct = async (productQuery) => {
  const res = await fetchApiHub('/action/product/getProduct', { method: 'POST' }, { ...productQuery });
  return res;
};
export const getBulbORRugModelProducts = async (productQuery) => {
  const res = await fetchApiHub('/action/product/getBulbORRugModelProducts', { method: 'POST' }, { ...productQuery });
  return res;
};
export const getAvailabilityMessage = async (sku: string) => {
  const res = await fetchApiHub('/action/product/getAvailabilityMessage', { method: 'POST' }, { sku });
  return res;
};
export const shareProductLink = async (email: string, pdpShareLink: string) => {
  const res = await fetchApiHub('/action/product/shareProductLink', { method: 'POST' }, { email, pdpShareLink });
  return res;
};
export const getShopCollectionProducts = async (path: String) => {
  const res = await fetchApiHub('/action/product/getShopCollectionProducts', { method: 'POST' }, { path });
  return res;
};
export const getProductRecommendationProducts = async (path: String) => {
  const res = await fetchApiHub('/action/product/getProductRecommendationProducts', { method: 'POST' }, { path });
  return res;
};
