export const mapLocaleToMeaningfulFormat = (locale: string) => {
  switch (locale) {
    case 'en':
      return {
        language: 'English',
        name: 'Name',
        footerTitle: 'STAY IN THE KNOW',
        footerSubtitle: 'Be the first to find out about trending styles, new releases and sales.',
        footerHashtag: '#MyShadesofLight',
        join: 'Join',
        shopCatalog: 'Shop Our Catalog',
        privacyPolicy: 'Privacy Policy',
        termCondition: 'Terms & Conditions',
        accessibility: 'Accessibility',
        ccpaPolicy: 'CCPA Policy',
        shadesOfLightReserved: '© Shades of Light All Rights Reserved',
        copyrightSymbol: '©',
        account: 'Account',
        myList: 'My List',
        cart: 'Cart',
        logOut: 'Log out',
        exploreAll: 'Explore All',
        siteMap: 'Sitemap',
        logInAccount: 'Log in to Your Account',
        email: 'Email',
        password: 'Password',
        rememberMe: 'Remember me',
        logIn: 'Log In',
        forgotPassword: 'Forgot your password?',
        createAccount: 'Create an Account',
        logo: 'Logo',
        home: 'Home',
        profile: 'Profile',
        defaultAddress: 'Default Address',
        update: 'Update',
        addressBook: 'Address Book',
        defaultCreditCard: 'Default Credit Card',
        manageCard: 'Manage Credit Card',
        manageList: 'Manage My List',
        recentOrder: 'Your Recent Order',
        tradeAccountCompanyName: 'Company Name',
        tradeAccountId: 'Trade Account ID',
        orderHistory: 'Order History',
        tradeAccount: 'Trade Account',
        requestTradeAccount: 'Request a Trade Account',
        openTradeAccount: 'Open Trade Account',
        communicationPreference: 'Communication Preferences',
        termsConditions: 'Terms & Conditions',
        trendingNow: 'Trending Now',
        shop: 'Shop',
        allEmail: 'All email',
        catalog: 'Catalog',
        shopAll: 'Shop All',
        unitPrice: 'Unit Price',
        quantity: 'Qty',
        total: 'Total',
        addToCart: 'Add to Cart',
        removeFromList: 'Remove from My List',
        color: 'Color',
        finish: 'Finish',
        shareListByEmail: 'Share your list by email',
        addAllToCart: 'Add all to cart',
        orderId: 'Order ID',
        orderDate: 'Order Date',
        status: 'Status',
        orderTotal: 'Order Total',
        noOrderFound: 'No orders have been placed with this account. Something missing? We can help.',
        yourListIsEmpty: 'Your list is empty',
        continueShopping: 'Continue Shopping',
        addNewAddress: 'Add New Address',
        noSavedAddress: 'You dont have any saved addresses.',
        setAsDefaultAddress: 'Set as default address',
        edit: 'Edit',
        delete: 'Delete',
        passwordlength: 'Password length: 8-40 chars',
        passwordDifferentFromUserName: 'Must be different from username',
        passwordNotSpace: 'Must not contain spaces',
        passwordOneUpperCase: 'Must contain 1 uppercase character (A-Z)',
        passwordOneDigit: 'Must contain 1 digit from 0-9',
        passwordMustContain: 'Must contain 1 special character “^[]’@#$%&*()-_+=|}{;:<,.>/?”',
        passwordsMustMeet: 'Passwords must meet the following requirements:',
        send: 'Send',
        passwordResetSentTo: 'Password reset has been sent to ',
        resetPassword: 'Reset Password',
        emailLinkResetPassword: "Enter your email address, and we'll email you a link to reset your password.",
        confirmPassword: 'Confirm Password',
        signUpEmailList: 'Sign up for our email list',
        forgotYourPassword: 'Forgot Your Password',
        addresses: 'Addresses',
        shippingAddress: 'Shipping Address',
        billingAddress: 'Billing Address',
        paymentMethod: 'Payment Method',
        creditCard: 'Credit Card',
        cartSummary: 'Cart Summary',
        subTotal: 'Subtotal',
        shipping: 'Shipping',
        tax: 'Tax',
        orderDetails: 'Order Details',
        statusShipped: 'Status: Shipped',
        orderNumber: 'Order Number',
        backOrderHistory: 'Back to Order History',
        track: 'Track',
        ending: 'Ending',
        expiry: 'Expiry',
        creditCards: 'Credit Cards',
        addCreditCard: 'Add Credit Card',
        noSavedCreditCard: 'You don’t have any saved credit cards.',
        setAsDefaultCreditCard: 'Set as default credit card',
        firstName: 'First Name',
        lastName: 'Last Name',
        company: 'Company',
        streetName: 'Address Line 1',
        streetNumber: 'Address Line 2',
        weCannotShip: 'We cannot ship to PO Boxes',
        country: 'Country',
        chooseCountry: 'Choose a country',
        city: 'City',
        stateRegion: 'State/Region',
        postalCode: 'Zip Code',
        phoneNumber: 'Phone Number',
        extension: 'Extension',
        setDefaultAddress: 'Set as default address',
        save: ' Save',
        cancel: ' Cancel',
        deleteConfirmation: 'Delete Confirmation',
        sureWantDelete: ' Are you sure want to delete this address from your account?',
        sureWantSetAddress: ' Are you sure want set this address as a default address in your account?',
        editAddress: 'Edit Address',
        updateAddress: 'Update Address',
        SetDefaultCard: 'Set as default credit card',
        sureWantDefaultCard: ' Are you sure want set this credit card as a default in your account?',
        creditCardNumber: 'Credit Card Number',
        nameOnCard: 'Name on Card',
        expirationDate: 'Expiration Date',
        cVV: 'CVV',
        setdefaultCreditCard: 'Set as default credit card',
        deleteCreditCard: 'Delete Credit Card',
        sureWantDeleteCard: 'Are you sure want to delete this credit card from your account?',
        editCard: 'Edit Credit Card',
        updateCard: 'Update Credit Card',
        close: 'Close',
        shareMyList: 'Share My List',
        shareByLink: 'Share via Link',
        shareByEmail: 'Share via Email',
        anyoneCanViewList: 'Anyone with the link can view your list without making edits.',
        recipientsCanViewList: 'Recipients can view your list without edits. ',
        emailAddress: 'Email Address',
        message: 'Message',
        share: 'Share',
        copy: 'Copy',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        removeProductConfirmMessage: 'Are you sure you want to remove this product from your list?',
        removeProduct: 'Remove this product',
        sendCatalog: 'Please send me a catalog.',
        yourPrimaryFocus: 'Your Primary Focus',
        hearAboutUs: 'How did you hear about us?',
        select: 'Select',
        items: 'items',
        accessoryCategory: 'accessoryCategory',
        cardAddedSuccessfully: 'Credit card added successfully.',
        cardNotActive: 'This Credit Card is not active.',
        shareYourList: 'Share your list',
        forgotPasswordBreadcrumb: 'Forgot Password',
        resetPasswordBreadcrumb: 'Reset Password',
        more: 'more',
        myListEmptyMessage: 'Your list is empty.',
        addressEmptyMessage: 'You don’t have any saved addresses.',
        recentOrderEmptyMessage: 'You don’t have any recent orders.',
        suggestedSearches: 'Suggested Searches',
        content: 'Content',
        relatedProduct: 'Related product',
        viewMoreResult: 'View More Results',
        signOut: 'Sign Out',
        back: 'Back',
        addressExtension: 'Ext.',
        remove: 'Remove',
        salesTax: 'Sales Tax',
        taxCalculateAtCheckout: 'Calculated at Checkout',
        havePromoCode: 'Have a promo code?',
        enterPromoCode: 'Enter Promo Code',
        apply: 'Apply',
        promoCodeApplied: 'Code Applied Successfully.',
        invalidPromoCode: 'Invalid Promotion Code',
        checkout: 'Checkout',
        checkoutManualOrder: 'Checkout Offline Order',
        contact: 'Contact Us',
        shippingInformation: 'Shipping Information',
        ReturnAndExchange: 'Return & Exchange',
        confirmAndPlaceOrder: 'Confirm & Place Order',
        tbd: 'TBD',
        calculatedAtCheckout: 'Calculated at Checkout',
        continueToCheckoutGuest: 'Continue to checkout. You will be able to create an account with us later.',
        checkoutAsGuest: 'Checkout as a Guest',
        products: 'Products',
        clearAll: 'Clear All',
        productType: 'Product type',
        readyToShip: 'Ready to Ship',
        searchResultsFor: 'Search Results for',
        filter: 'Filter',
        productCategoryPage: 'Product Category Page',
        blog: 'Blog',
        quickView: 'Quick View',
        filters: 'Filters:',
        allProducts: 'All Products',
        noSearchResultTitle: "We're sorry. We weren't able to find any results for",
        searchTips: 'Search Tips',
        makeSpelledCorrectly: 'Make sure all words are spelled correctly.',
        tryDifferentKeywords: 'Try different keywords.',
        tryGeneralKeywords: 'Try more general keywords.',
        needHelp: 'Need help finding something? Call ',
        popularCategories: ' Popular Categories',
        youMayAlsoLike: 'You May Also Like',
        subscriptions: 'Subscriptions',
        communicationPreferences: 'Communication Preferences',
        confirmEmailAddress: 'Confirm Email Address',
        emailPreferences: 'Email Preferences',
        emailUpdate: 'Please keep me  up-to-date on all Shades of Light emails',
        keepMeUpdate: 'Just keep me up-to-date on ',
        newCatalogPreview: 'New Catalog Previews',
        looksAndStyles: 'Trending Looks & Styles',
        newItemIntro: 'New Item Introductions',
        decoratingTips: 'Decorating Tips & Ideas',
        saleAndSpecials: 'Sales & Specials',
        submit: 'Submit',
        emailSubscription:
          'By providing this information, you are opting to receive email communications from Shades of Light.',
        unsubscribeEmail: 'Unsubscribe from all emails',
        unsubscribe: 'Unsubscribe',
        unsubscribeCatalogMail: 'Unsubscribe from receiving a catalog by mail',
        catalogByMail: 'Catalog by Mail',
        relatedCategories: 'Related Categories',
        returnToCart: 'Return to Cart',
        continueToPayment: 'Continue to Payment',
        useSameAsShipping: 'Use same as shipping',
        keepMeUpdateOnTrands: 'Keep me up to date on trends, new arrivals and promotions',
        sendCatalogToAddress: 'Send a catalog to my shipping address',
        payment: 'Payment',
        reviewAndOrder: 'Review & Place Order',
        step: 'Step',
        actualSize: 'Actual Size:',
        bulbBase: 'Bulb Base:',
        Exclusive: 'Exclusive:',
        Material: 'Material:',
        maxBulbWattage: 'Max Bulb Wattage:',
        numberOFLights: 'Number of Lights:',
        switchLocation: 'Switch Location:',
        switchType: 'Switch Type:',
        addMyList: '+ Add to My List',
        addGiftRegistry: '+ Add to Gift Registry',
        inStockReadyShip: 'In stock and ready to ship',
        description: 'Description',
        specifications: 'Specifications',
        downrodsIncluded: 'Downrods Included:',
        reviews: 'Reviews',
        YouMayAlsoLike: 'You May Also Like',
        sku: 'SKU:',
        browserNotSupportVideo: 'Your browser does not support the video tag.',
        onOff: 'On/Off',
        catalogRequestForm: 'Catalog Request Form',
        subscribeNewsletter: 'Subscribe to our newsletter. Inspiration and design tips straight to your inbox.',
        sendCatalogByMail: 'Send me a catalog by mail',
        viewLatestCatalog: 'View and shop the latest catalog Online',
        catalogRequestFormDescription:
          'Request your free Shades of Light catalog below. Our lighting & home decor catalog is mailed seven times annually. View our',
        referral: 'How did you find us?',
        add: 'Add',
        addBulbsFor: 'Add Bulbs for your ',
        seeMoreCompatibleBulbs: 'See More Compatible Bulbs',
        keepShopping: 'Keep Shopping',
        noResult: 'No results for the keyword',
        yourOrderIsConfirmed: 'Your Order is Confirmed',
        thankYouForShopping:
          'Thank you for shopping at Shades of Light. Below is a summary of your recent order. We will send you another email when your order ships.',
        yourOrderConfirmed: 'For orders with backordered items, your items will ship as they become available.',
        goToAccount: 'Go to Account',
        tradeAccountProgram: 'Trade Account Program',
        whoCanQualify: 'Who Can Qualify',
        accountSetUp: 'Account Set Up',
        contactHeading: 'Contact',
        followOnInstagram: 'Follow us on Instagram',
        processing: 'Processing',
        orderNo: 'Order No',
        orderHash: 'Order #',
        yourCartIsEmpty: 'Your cart is empty',
        subtotal: 'Subtotal',
        viewCartAndCheckout: 'View cart and checkout',
        sureWantToRemove: 'Are you sure you want to remove this product?',
        yes: 'Yes',
        no: 'No',
        size: 'size',
        popularTags: 'Popular Tags',
        tags: 'Tags:',
        categories: 'Categories',
        inspiredSpacesBlog: 'Inspired Spaces Blog',
        loading: 'Loading ...',
        continueToReview: 'Continue To review',
        customLoaderMessage: 'We apologize for the delay',
        orderSummary: 'Order Summary',
        or: 'or',
        viewFullDetails: 'View Full Details',
        currentCatalog: 'Current Catalog',
        previousCatalogs: 'Previous Catalogs',
        catalogLandingPage: 'Catalog Landing Page',
        optional: 'optional',
        recipientName: 'Recipient Name',
        recipientEmail: 'Recipient Email Address',
        loadMore: 'Load More Results',
        showPrevious: 'Show Previous',
        colon: ':',
        continueToCheckoutGuestMobile: 'You can create an account at the end of checkout.',
        promoSavings: 'Promo Savings',
        discount: 'Discount',
        reCaptchaError: 'Please complete the reCaptcha',
        recentCatalogs: 'recent catalogs',
        nextCatalogMail: 'to get inspired and learn when to expect our next catalog in the mail!',
        searchResults: 'Search Results',
        featured: 'Featured',
        registerNow: 'Register Now',
        addNewCreditCard: 'Add New Credit Card',
        cartIsEmpty: 'Your cart is empty',
        looksLikeNoItem: 'Looks like you have not added anything in your Cart.',
        explore: 'Explore',
        readMore: 'Read More',
        readLess: 'Read Less',
        successfulSubscription: 'You have successfully subscribed to our e-mails',
        shareOnFacebook: 'Share on Facebook (opens in new window)',
        shareOnTwitter: 'Share on Twitter (opens in new window)',
        shareOnPinterest: 'Share on Pinterest (opens in new window)',
        shareOnHauzz: 'Share on Houzz (opens in new window)',
        shareOnEmail: 'Share by Email',
        sharedMyList: 'Shared My List',
        notAvailable: 'Not Available',
        invalidCart: 'One or more products are no longer available. Please remove to continue to checkout.',
        invalidWishlist: 'One or more products are no longer available. Please remove unavailable products.',
        thisProductNoLongerAvailable: 'This product is no longer available.',
        noOrderRequested: 'No order that you have requested',
        bulbModelSubHeading: "We've found some bulbs that would look great with your new light fixture:",
        viewDownrodSelectionGuide: 'View Downrod Selection Guide',
        safetyWarningDownrods: 'Safety Warning: Downrods should not be joined together.',
        purchaseAccessoriesOnly: 'Purchase Accessories Only',
        optionalAccessories: 'Optional Accessories',
        suggestAddressHeading:
          "We couldn't verify the address you entered. Please choose the suggested correction or proceed with the address you entered.",
        verifyShippingAddress: 'Verify Shipping Address',
        verifyBillingAddress: 'Verify Billing Address',
        useCorrectedAddress: 'Use Corrected Address:',
        useEnteredAddress: 'Use Address as Entered:',
        enterTheCatalog: 'Please enter the Source Code from the back of your catalog.',
        thankYou: 'Thank You!',
        seeMoreCompatibleRugs: 'See More Rug Pads & Sizes',
        tradeAccountStatus: 'Your trade account is',
        colorUnavailable: 'This color is currently unavailable for purchase.',
        sizeUnavailable: 'This actualSize is currently unavailable for purchase.',
        accountLogIn: 'Account Log In',
        rugModelSubHeading: 'Add a non-slip pad for your new rug to prevent premature wear:',
        shareCheckOut: 'Check out ',
        fromShadesOfLight: ' from Shades of Light ',
        shadesOfLightLogo: 'Shades of Light Logo',
        yourPrice: 'Your Price',
        coloradoDeliveryFee: '*includes $0.28 Colorado Retail Delivery Fee',
        emailUs: 'or email us',
        pleaseSelect: 'Please Select',
        impersonate: 'Impersonate',
        sessionTimeOut: 'Session timeout',
        reset: 'Reset',
        signInAs: 'Sign in as',
        assistedServiceAgent: 'Assisted service agent',
        notAssigned: 'Not assigned',
        endSession: 'End session',
        location: 'Location',
        emailAlreadyExists: 'Already have an account?',
        loginNavigation: 'login',
        existingEmailId: 'with your current credentials or use a different email to continue with guest checkout.',
        warning: 'Warning',
        forMoreInformation: 'For more information, go to www.P65Warnings.ca.gov',
        californiaResidentsWARNINGS: 'California Residents See PROP 65 WARNINGS',
        sitemap: 'Sitemap',
        inspiration: 'Inspiration',
        actualColor: 'actualColor',
        resetFilters: 'Re-set Filters',
        clickHere: 'click here.',
        successfullyUpdatedYourPreferences: 'You have successfully updated your preferences.',
        changeYourPreferences:
          'You may change your preferences and manage your subscription at any time by entering your registered email address in the preference center.',
        unSubscribed: 'You Have Been Unsubscribed!',
        sorryToSeeYouGo: "We're sorry to see you go. If you ever change your mind,",
        signInAgain: 'we welcome you to sign up again at any time.',
        needToUnsubscribe: 'Need to unsubscribe?',
        unsubscribeFromOurEmail: 'If at any time you wish to unsubscribe from our email communications, please',
        catalogUnsubscribe: 'Catalog Unsubscribe',
        confirmAddress: 'Confirm Address',
        noEmailSubscription: 'Email entered has no active subscriptions',
        shopCollection: 'Shop Collection',
        youMayAlsoNeed: 'You May Also Need',
        viewAll: 'View All',
        discountTextFormat: '{0}% off on this item with code {1} during {2}. Offer ends {3}.',
        masterVariantKey: 'isMasterVariant',
        recentlyViewed: 'Recently Viewed',
        shadesOfLight: 'Shades of Light',
        clearanceVariantMessage: 'This color is not on sale, but available ',
        clearanceVariantRugMessage: 'This color/size selection is not on sale, but available ',
        here: 'here',
        Additional: 'Additional',
        login_here: 'Login here',
      };
    default:
      return {
        language: 'English',
        name: 'Name',
        footerTitle: 'STAY IN THE KNOW',
        footerSubtitle: 'Be the first to find out about trending styles, new releases and sales.',
        footerHashtag: '#MyShadesofLight',
        join: 'Join',
        shopCatalog: 'Shop Our Catalog',
        privacyPolicy: 'Privacy Policy',
        termCondition: 'Terms & Conditions',
        accessibility: 'Accessibility',
        ccpaPolicy: 'CCPA Policy',
        shadesOfLightReserved: '© Shades of Light All Rights Reserved',
        copyrightSymbol: '©',
        account: 'Account',
        myList: 'My List',
        cart: 'Cart',
        logOut: 'Log out',
        exploreAll: 'Explore All',
        siteMap: 'Sitemap',
        logInAccount: 'Log in to Your Account',
        email: 'Email',
        password: 'Password',
        rememberMe: 'Remember me',
        logIn: 'Log In',
        forgotPassword: 'Forgot your password?',
        createAccount: 'Create an Account',
        logo: 'Logo',
        home: 'Home',
        profile: 'Profile',
        defaultAddress: 'Default Address',
        update: 'Update',
        addressBook: 'Address Book',
        defaultCreditCard: 'Default Credit Card',
        manageCard: 'Manage Credit Card',
        manageList: 'Manage My List',
        recentOrder: 'Your Recent Order',
        orderHistory: 'Order History',
        tradeAccount: 'Trade Account',
        requestTradeAccount: 'Request a Trade Account',
        communicationPreference: 'Communication Preferences',
        termsConditions: 'Terms & Conditions',
        trendingNow: 'Trending Now',
        shop: 'Shop',
        allEmail: 'All email',
        catalog: 'Catalog',
        shopAll: 'Shop All',
        unitPrice: 'Unit Price',
        quantity: 'Qty',
        total: 'Total',
        addToCart: 'Add to Cart',
        removeFromList: 'Remove from My List',
        color: 'Color',
        finish: 'Finish',
        shareListByEmail: 'Share your list by email',
        addAllToCart: 'Add all to cart',
        orderId: 'Order ID',
        orderDate: 'Order Date',
        downrodsIncluded: 'Downrods Included:',
        status: 'Status',
        orderTotal: 'Order Total',
        noOrderFound: 'No orders have been placed with this account. Something missing? We can help.',
        yourListIsEmpty: 'Your list is empty',
        continueShopping: 'Continue Shopping',
        addNewAddress: 'Add New Address',
        noSavedAddress: 'You dont have any saved addresses.',
        setAsDefaultAddress: 'Set as default address',
        edit: 'Edit',
        delete: 'Delete',
        actualColor: 'actualColor',
        passwordlength: 'Password length: 8-40 chars',
        passwordDifferentFromUserName: 'Must be different from username',
        passwordNotSpace: 'Must not contain spaces',
        passwordOneUpperCase: 'Must contain 1 uppercase character (A-Z)',
        passwordOneDigit: 'Must contain 1 digit from 0-9',
        passwordMustContain: 'Must contain 1 special character “^[]’@#$%&*()-_+=|}{;:<,.>/?”',
        passwordsMustMeet: 'Passwords must meet the following requirements:',
        send: 'Send',
        passwordResetSentTo: 'Password reset has been sent to ',
        resetPassword: 'Reset Your Password',
        emailLinkResetPassword: "Enter your email address, and we'll email you a link to reset your password.",
        confirmPassword: 'Confirm Password',
        signUpEmailList: 'Sign up for our email list',
        forgotYourPassword: 'Forgot Your Password',
        addresses: 'Addresses',
        shippingAddress: 'Shipping Address',
        billingAddress: 'Billing Address',
        paymentMethod: 'Payment Method',
        creditCard: 'Credit Card',
        cartSummary: 'Cart Summary',
        subTotal: 'Subtotal',
        shipping: 'Shipping',
        tax: 'Tax',
        orderDetails: 'Order Details',
        statusShipped: 'Status: Shipped',
        orderNumber: 'Order Number',
        backOrderHistory: 'Back to Order History',
        track: 'Track',
        ending: 'Ending',
        expiry: 'Expiry',
        creditCards: 'Credit Cards',
        addCreditCard: 'Add Credit Card',
        noSavedCreditCard: 'You don’t have any saved credit cards.',
        setAsDefaultCreditCard: 'Set as default credit card',
        firstName: 'First Name',
        lastName: 'Last Name',
        company: 'Company',
        streetName: 'Address Line 1',
        streetNumber: 'Address Line 2',
        weCannotShip: 'We cannot ship to PO Boxes',
        country: 'Country',
        chooseCountry: 'Choose a country',
        city: 'City',
        stateRegion: 'State/Region',
        postalCode: 'Zip Code',
        phoneNumber: 'Phone Number',
        extension: 'Extension',
        setDefaultAddress: 'Set as default address',
        save: ' Save',
        cancel: ' Cancel',
        deleteConfirmation: 'Delete Confirmation',
        sureWantDelete: ' Are you sure want to delete this address from your account?',
        sureWantSetAddress: ' Are you sure want set this address as a default address in your account?',
        editAddress: 'Edit Address',
        updateAddress: 'Update Address',
        SetDefaultCard: 'Set as default credit card',
        sureWantDefaultCard: ' Are you sure want set this credit card as a default in your account?',
        creditCardNumber: 'Credit Card Number',
        nameOnCard: 'Name on Card',
        expirationDate: 'Expiration Date',
        cVV: 'CVV',
        setdefaultCreditCard: 'Set as default credit card',
        deleteCreditCard: 'Delete Credit Card',
        sureWantDeleteCard: 'Are you sure want to delete this credit card from your account?',
        editCard: 'Edit Credit Card',
        updateCard: 'Update Credit Card',
        close: 'Close',
        shareMyList: 'Share My List',
        shareByLink: 'Share via Link',
        shareByEmail: 'Share via Email',
        anyoneCanViewList: 'Anyone with the link can view your list without making edits.',
        recipientsCanViewList: 'Recipients can view your list without edits. ',
        emailAddress: 'Email Address',
        message: 'Message',
        share: 'Share',
        copy: 'Copy',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        removeProductConfirmMessage: 'Are you sure you want to remove this product from your list?',
        removeProduct: 'Remove this product',
        sendCatalog: 'Please send me a catalog.',
        yourPrimaryFocus: 'Your Primary Focus',
        hearAboutUs: 'How did you hear about us?',
        select: 'Select',
        items: 'items',
        accessoryCategory: 'accessoryCategory',
        cardAddedSuccessfully: 'Credit card added successfully.',
        cardNotActive: 'This credit card is not active.',
        shareYourList: 'Share your list',
        forgotPasswordBreadcrumb: 'Forgot Password',
        resetPasswordBreadcrumb: 'Reset Password',
        more: 'more',
        myListEmptyMessage: 'Your list is empty.',
        addressEmptyMessage: 'You don’t have any saved addresses.',
        recentOrderEmptyMessage: 'You don’t have any recent orders.',
        suggestedSearches: 'Suggested Searches',
        content: 'Content',
        relatedProduct: 'Related product',
        viewMoreResult: 'View More Results',
        signOut: 'Sign Out',
        addressExtension: 'Ext.',
        remove: 'Remove',
        salesTax: 'Sales Tax',
        taxCalculateAtCheckout: 'Calculated at Checkout',
        havePromoCode: 'Have a promo code?',
        enterPromoCode: 'Enter Promo Code',
        apply: 'Apply',
        promoCodeApplied: 'Code Applied Successfully.',
        invalidPromoCode: 'Invalid Promotion Code',
        checkout: 'Checkout',
        contact: 'Contact Us',
        shippingInformation: 'Shipping Information',
        ReturnAndExchange: 'Return & Exchange',
        confirmAndPlaceOrder: 'Confirm & Place Order',
        tbd: 'TBD',
        calculatedAtCheckout: 'Calculated at Checkout',
        continueToCheckoutGuest: 'Continue to checkout. You will be able to create an account with us later.',
        checkoutAsGuest: 'Checkout as a Guest',
        products: 'Products',
        clearAll: 'Clear All',
        productType: 'Product type',
        readyToShip: 'Ready to Ship',
        searchResultsFor: 'Search Results for',
        filter: 'Filter',
        productCategoryPage: 'Product Category Page',
        blog: 'Blog',
        quickView: 'Quick View',
        appliedFilters: 'Applied Filters:',
        allProducts: 'All Products',
        noSearchResultTitle: "We're sorry. We weren't able to find any results for",
        searchTips: 'Search Tips',
        makeSpelledCorrectly: 'Make sure all words are spelled correctly.',
        tryDifferentKeywords: 'Try different keywords.',
        tryGeneralKeywords: 'Try more general keywords.',
        needHelp: 'Need help finding something? Call ',
        popularCategories: ' Popular Categories',
        youMayAlsoLike: 'You May Also Like',
        subscriptions: 'Subscriptions',
        communicationPreferences: 'Communication Preferences',
        confirmEmailAddress: 'Confirm Email Address',
        emailPreferences: 'Email Preferences',
        emailUpdate: 'Please keep me  up-to-date on all Shades of Light emails',
        keepMeUpdate: 'Just keep me up-to-date on ',
        newCatalogPreview: 'New Catalog Previews',
        looksAndStyles: 'Trending Looks & Styles',
        newItemIntro: 'New Item Introductions',
        decoratingTips: 'Decorating Tips & Ideas',
        saleAndSpecials: 'Sales & Specials',
        submit: 'Submit',
        emailSubscription:
          'By providing this information, you are opting to receive email communications from Shades of Light.',
        unsubscribeEmail: 'Unsubscribe from all emails',
        unsubscribe: 'Unsubscribe',
        unsubscribeCatalogMail: 'Unsubscribe from receiving a catalog by mail',
        catalogByMail: 'Catalog by Mail',
        relatedCategories: 'Related Categories',
        returnToCart: 'Return to Cart',
        continueToPayment: 'Continue to Payment',
        useSameAsShipping: 'Use same as shipping',
        keepMeUpdateOnTrands: 'Keep me up to date on trends, new arrivals and promotions',
        sendCatalogToAddress: 'Send a catalog to my shipping address',
        payment: 'Payment',
        reviewAndOrder: 'Review & Place Order',
        step: 'Step',
        actualSize: 'Actual Size:',
        bulbBase: 'Bulb Base:',
        Exclusive: 'Exclusive:',
        Material: 'Material:',
        maxBulbWattage: 'Max Bulb Wattage:',
        numberOFLights: 'Number of Lights:',
        switchLocation: 'Switch Location:',
        switchType: 'Switch Type:',
        addMyList: '+ Add to My List',
        optionalAccessories: 'Optional Accessories',
        addGiftRegistry: '+ Add to Gift Registry',
        inStockReadyShip: 'In stock and ready to ship',
        description: 'Description',
        specifications: 'Specifications',
        reviews: 'Reviews',
        YouMayAlsoLike: 'You May Also Like',
        sku: 'SKU:',
        browserNotSupportVideo: 'Your browser does not support the video tag.',
        onOff: 'On/Off',
        catalogRequestForm: 'Catalog Request Form',
        subscribeNewsletter: 'Subscribe to our newsletter. Inspiration and design tips straight to your inbox.',
        sendCatalogByMail: 'Send me a catalog by mail',
        viewLatestCatalog: 'View and shop the latest catalog Online',
        catalogRequestFormDescription:
          'Request your free Shades of Light catalog below. Our lighting & home decor catalog is mailed seven times annually. View our',
        referral: 'How did you find us?',
        add: 'Add',
        addBulbsFor: 'Add Bulbs for your',
        seeMoreCompatibleBulbs: 'See More Compatible Bulbs',
        keepShopping: 'Keep Shopping',
        noResult: 'No results for the keyword',
        yourOrderIsConfirmed: 'Your Order is Confirmed',
        thankYouForShopping:
          'Thank you for shopping at Shades of Light. Below is a summary of your recent order. We will send you another email when your order ships.',
        yourOrderConfirmed: 'For orders with backordered items, your items will ship as they become available.',
        goToAccount: 'Go to Account',
        tradeAccountProgram: 'Trade Account Program',
        whoCanQualify: 'Who Can Qualify',
        accountSetUp: 'Account Set Up',
        contactHeading: 'Contact',
        followOnInstagram: 'Follow us on Instagram',
        processing: 'Processing',
        orderNo: 'Order No',
        yourCartIsEmpty: 'Your Cart is empty',
        subtotal: 'Subtotal',
        viewCartAndCheckout: 'View Cart and Checkout',
        sureWantToRemove: 'Are you sure you want to remove this product?',
        yes: 'Yes',
        no: 'No',
        popularTags: 'Popular Tags',
        tags: 'Tags:',
        categories: 'Categories',
        inspiredSpacesBlog: 'Inspired Spaces Blog',
        loading: 'Loading ...',
        continueToReview: 'Continue To review',
        customLoaderMessage: 'We apologize for the delay',
        orderSummary: 'Order Summary',
        or: 'or',
        viewFullDetails: 'View Full Details',
        currentCatalog: 'Current Catalog',
        previousCatalogs: 'Previous Catalogs',
        catalogLandingPage: 'Catalog Landing Page',
        loadMore: 'Load More Results',
        showPrevious: 'Show Previous',
        colon: ':',
        continueToCheckoutGuestMobile: 'You can create an account at the end of checkout.',
        promoSavings: 'Promo Savings',
        reCaptchaError: 'Please complete the reCaptcha',
        recentCatalogs: 'recent catalogs',
        nextCatalogMail: 'to get inspired and learn when to expect our next catalog in the mail!',
        searchResults: 'Search Results',
        featured: 'Featured',
        registerNow: 'Register Now',
        addNewCreditCard: 'Add New Credit Card',
        cartIsEmpty: 'Your cart is empty',
        looksLikeNoItem: 'Looks Like you have not added anything in your Cart.',
        explore: 'Explore',
        readMore: 'Read More',
        readLess: 'Read Less',
        successfulSubscription: 'You have successfully subscribed to our e-mails',
        shareOnFacebook: 'Share on Facebook (opens in new window)',
        shareOnTwitter: 'Share on Twitter (opens in new window)',
        shareOnPinterest: 'Share on Pinterest (opens in new window)',
        shareOnHauzz: 'Share on Houzz (opens in new window)',
        shareOnEmail: 'Share by Email',
        sharedMyList: 'Shared My List',
        notAvailable: 'Not Available',
        invalidCart: 'One or more products are no longer available. Please remove to continue to checkout.',
        invalidWishlist: 'One or more products are no longer available. Please remove unavailable products.',
        thisProductNoLongerAvailable: 'This product is no longer available.',
        noOrderRequested: 'No order that you have requested',
        bulbModelSubHeading: "We've found some bulbs that would look great with your new light fixture:",
        viewDownrodSelectionGuide: 'View Downrod Selection Guide',
        safetyWarningDownrods: 'Safety Warning: Downrods should not be joined together.',
        purchaseAccessoriesOnly: 'Purchase Accessories Only',
        suggestAddressHeading:
          "We couldn't verify the address you entered. Please choose the suggested correction or proceed with the address you entered.",
        verifyShippingAddress: 'Verify Shipping Address',
        verifyBillingAddress: 'Verify Billing Address',
        useCorrectedAddress: 'Use Corrected Address:',
        useEnteredAddress: 'Use Address as Entered:',
        enterTheCatalog: 'Please enter the Source Code from the back of your catalog.',
        thankYou: 'Thank You!',
        seeMoreCompatibleRugs: 'See More Rug Pads & Sizes',
        colorUnavailable: 'This color is currently unavailable for purchase.',
        sizeUnavailable: 'This actualSize is currently unavailable for purchase.',
        accountLogIn: 'Account Log In',
        rugModelSubHeading: 'Add a non-slip pad for your new rug to prevent premature wear:',
        shareCheckOut: 'Check out ',
        fromShadesOfLight: ' from Shades of Light',
        shadesOfLightLogo: 'Shades of Light Logo',
        yourPrice: 'Your Price',
        coloradoDeliveryFee: '*includes $0.28 Colorado Retail Delivery Fee',
        emailUs: 'or email us',
        pleaseSelect: 'Please Select',
        impersonate: 'Impersonate',
        sessionTimeOut: 'Session timeout',
        reset: 'Reset',
        signInAs: 'Sign in as',
        assistedServiceAgent: 'Assisted service agent',
        notAssigned: 'Not assigned',
        endSession: 'End session',
        location: 'Location',
        emailAlreadyExists: 'Already have an account?',
        loginNavigation: 'login',
        existingEmailId: 'with existing email id',
        warning: 'Warning',
        forMoreInformation: 'For more information, go to www.P65Warnings.ca.gov',
        californiaResidentsWARNINGS: 'California Residents See PROP 65 WARNINGS',
        sitemap: 'Sitemap',
        inspiration: 'Inspiration',
        resetFilters: 'Re-set Filters',
        clickHere: 'click here.',
        successfullyUpdatedYourPreferences: 'You have successfully updated your preferences.',
        changeYourPreferences:
          'You may change your preferences and manage your subscription at any time by entering your registered email address in the preference center.',
        unSubscribed: 'You Have Been Unsubscribed!',
        sorryToSeeYouGo: "We're sorry to see you go. If you ever change your mind,",
        signInAgain: 'we welcome you to sign up again at any time.',
        needToUnsubscribe: 'Need to unsubscribe?',
        unsubscribeFromOurEmail: 'If at any time you wish to unsubscribe from our email communications, please',
        back: 'Back',
        catalogUnsubscribe: 'Catalog Unsubscribe',
        confirmAddress: 'Confirm Address',
        noEmailSubscription: 'Email entered has no active subscriptions',
        openTradeAccount: 'Open Trade Account',
        shopCollection: 'Shop Collection',
        youMayAlsoNeed: 'You May Also Need',
        viewAll: 'View All',
        discountTextFormat: '{0}% off on this item with code {1} during {2}. Offer ends {3}.',
        masterVariantKey: 'isMasterVariant',
        recentlyViewed: 'Recently Viewed',
        shadesOfLight: 'Shades Of Light',
        clearanceVariantMessage: 'This color is not on sale, but available ',
        clearanceVariantRugMessage: 'This color/size selection is not on sale, but available ',
        here: 'here',
        Additional: 'Additional',
        login_here: 'Login here',
      };
  }
};
