import { gtmEventData } from '@Types/gtm-events/Event';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const clearDataLayer = [
  'ecommerce',
  'user',
  'cjEvent', // TODO this needs to be refactored to use cjEvents in GTM instead of dataLayer
] as const;

export const gtmTrackEvent = (event: string, data: any, shouldClear?: boolean): void => {
  try {
    shouldClear = shouldClear ?? false;

    const eventObject: Partial<gtmEventData> = {
      event: event,
      _clear: shouldClear,
    };

    // magic sauce - add data object into the event object to record
    Object.assign(eventObject, data);

    // GTM likes to prevent collision with a forced clear on particular objects
    clearDataLayer.forEach((key) => {
      if (eventObject.hasOwnProperty(key)) {
        window?.dataLayer?.push({ [key]: null }); // Clear previous set object
      }
    });

    // push the event to the dataLayer
    window?.dataLayer?.push(eventObject);
  } catch (error) {
    console.error('GTM dataLayer is not available:', event, error);
  }
};

export const gtmTrackEventError = (error: Error, eventName: string) => {
  console.error(`GTM event '${eventName}' failed:`, error);
};
