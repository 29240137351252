import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';

export const getConfig = () => {
  return useSWR('/action/config/getConfig', fetchApiHub, revalidateOptions);
};

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings');
};

export const getfacettedURLMappings = async () => {
  return await fetchApiHub('/action/project/getfacettedURLMappings', { method: 'POST' }, {});
};
