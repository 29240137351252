import aa from 'search-insights';
import {
  TaddedToCartObjectIDs,
  TaddedToCartObjectIDsAfterSearch,
  TclickedFilters,
  TclickedObjectIDs,
  TclickedObjectIDsAfterSearch,
  TconvertedFilters,
  TconvertedObjectIDs,
  TconvertedObjectIDsAfterSearch,
  TpurchasedObjectIDsAfterSearch,
  TviewedFilters,
  TviewedObjectIDs,
} from './algoliaTypes';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { clearAlgoliaQueryID } from './algoliaUtils';

let userToken;
let authenticatedUserToken;
export const algoliaUser = (account: any) => {
  const algoliaCookieToken = Cookies?.get('_ALGOLIA');
  const isLoggedIn = account?.accountId ? true : false;
  if (isLoggedIn) {
    authenticatedUserToken = account?.accountId;
  } else {
    authenticatedUserToken = undefined;
  }

  if (algoliaCookieToken) {
    userToken = algoliaCookieToken;
  } else {
    aa('getUserToken', {}, (err, anonymousToken) => {
      if (err) {
        console.error(err);
        return;
      }
      userToken = anonymousToken;
    });
  }
  aa('setUserToken', userToken);
};

export const algoliaConfig = async () => {
  const res = await fetchApiHub(`/action/config/getConfig`, { method: 'POST' }, {});
  try {
    aa('init', {
      appId: res.algolia.appId,
      apiKey: res.algolia.appAdminKey,
      useCookie: true,
    });

    if (typeof window != 'undefined') localStorage.setItem('currentIndex', res?.algolia?.indexNameProductsMain ?? '');

    console.log('the algolia app is initialized');
  } catch (error) {
    console.log('the algolia app is not initialized', error);
  }
};

export const clickedObjectIDsAfterSearch = ({
  index,
  eventName,
  queryID,
  objectIDs,
  positions,
}: TclickedObjectIDsAfterSearch) => {
  try {
    aa('clickedObjectIDsAfterSearch', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      queryID: queryID,
      objectIDs: objectIDs,
      positions: positions,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const convertedObjectIDsAfterSearch = ({
  index,
  eventName,
  queryID,
  objectIDs,
}: TconvertedObjectIDsAfterSearch) => {
  try {
    aa('convertedObjectIDsAfterSearch', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      queryID: queryID,
      objectIDs: objectIDs,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const addedToCartObjectIDsAfterSearch = ({
  eventName,
  index,
  queryID,
  objectIDs,
  objectData,
  value,
  currency,
}: TaddedToCartObjectIDsAfterSearch) => {
  try {
    aa('addedToCartObjectIDsAfterSearch' as any, {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      eventName: eventName,
      index: index,
      queryID: queryID,
      objectIDs: objectIDs,
      objectData: objectData,
      currency: currency,
      value: value,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const clickedObjectIDs = ({ index, eventName, objectIDs }: TclickedObjectIDs) => {
  try {
    aa('clickedObjectIDs', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      objectIDs: objectIDs,
    });

    // Remove the queryID for conversion event
    clearAlgoliaQueryID();
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const convertedObjectIDs = ({ index, eventName, objectIDs }: TconvertedObjectIDs) => {
  try {
    aa('convertedObjectIDs', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      objectIDs: objectIDs,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const addedToCartObjectIDs = ({ index, eventName, objectIDs, objectData, currency }: TaddedToCartObjectIDs) => {
  try {
    aa('addedToCartObjectIDs' as any, {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      objectIDs: objectIDs,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const purchasedObjectIDsAfterSearch = ({
  index,
  eventName,
  objectIDs,
  objectData,
  currency,
  value,
}: TpurchasedObjectIDsAfterSearch) => {
  try {
    aa('purchasedObjectIDsAfterSearch' as any, {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      objectIDs: objectIDs,
      objectData: objectData,
      value: value,
      currency: currency,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const clickedFilters = ({ index, eventName, filters }: TclickedFilters) => {
  try {
    aa('clickedFilters', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      filters: filters,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const convertedFilters = ({ index, eventName, filters }: TconvertedFilters) => {
  try {
    aa('convertedFilters', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      filters: filters,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const viewedObjectIDs = ({ index, eventName, objectIDs }: TviewedObjectIDs) => {
  try {
    aa('viewedObjectIDs', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      objectIDs: objectIDs,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};

export const viewedFilters = ({ index, eventName, filters }: TviewedFilters) => {
  try {
    aa('viewedFilters', {
      userToken: userToken,
      ...(authenticatedUserToken ? { authenticatedUserToken } : {}),
      index: index,
      eventName: eventName,
      filters: filters,
    });
  } catch (error) {
    console.log('the event was not triggered', error);
  }
};
