export const routerURL = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  account: '/account',
  orderHistory: '/account/order-history',
  myList: '/account/my-list',
  address: '/account/address',
  creditCard: '/account/credit-card',
  tradeAccounts: '/trade-account',
  orderDetails: '/account/order-details',
  cart: '/cart',
  continueShopping: '/',
  contactUs: '/pages/contact',
  shippingInfo: '/pages/shipping',
  returnExchange: '/pages/returns',
  checkout: '/checkout',
  communicationPreferences: '/pages/communication-preferences',
  checkoutAddress: '/checkout/addresses',
  checkoutPayment: '/checkout/payment',
  checkoutReview: '/checkout/review',
  search: '/search?q=',
  seeMoreBulbs: '/categories/all-light-bulbs',
  thankYou: '/thank-you',
  catalog: '/pages/catalog',
  shareMyList: '/share/my-list',
  hash: '#',
  designerTradeProgram: 'pages/trade',
  seeMoreRugs: '/categories/rug-pads',
  products: '/products',
  downrods: '/downrods',
  catalogRequest: '/catalog_request',
};
