import React from 'react';
import { useRouter } from 'next/router';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';

const CustomLoader: React.FC = () => {
  const router = useRouter();
  return (
    <div className="inset-0 grid h-[60vh] w-full bg-transparent">
      <div className="loading-full-screen mt-[10rem] flex justify-center self-center md:mt-0" />
      <h1 className="mt-6 text-center text-[1.3rem] font-semibold md:text-[1.688rem] lg:mt-4">
        {mapLocaleToMeaningfulFormat(router.locale).customLoaderMessage}
      </h1>
    </div>
  );
};

export default CustomLoader;
