import {
  getAccount,
  changePassword,
  confirm,
  requestConfirmationEmail,
  login,
  impersonateUser,
  logout,
  register,
  requestPasswordReset,
  resetPassword,
  update,
  addAddress,
  updateAddress,
  removeAddress,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  signupNewsLetter,
  getCustomerAddresses,
  validateAddress,
  getShippingLocations,
  validateGuestCheckout,
  checkSubscription,
  updateSubscription,
  catalogRequest,
  catalogUnsubscribe,
  catalogUnsubscribeFreshDesk,
} from '../../actions/account';
import {
  cartItems,
  addItem,
  checkout,
  orderHistory,
  orderDetails,
  ordersByMonths,
  removeItem,
  getShippingMethods,
  setShippingMethod,
  updateCart,
  updateItem,
  redeemDiscountCode,
  removeDiscountCode,
  addShoppingList,
  getRecentOrder,
  addMultipleItems,
  setSourceCode,
} from '../../actions/cart';
import { getWishlist, addToWishlist, removeLineItem, updateLineItem, shareWishlist } from '../../actions/wishlist';
import { createSession } from '../../actions/adyen';
import { UseAccount } from './UseAccount';
import { UseCart } from './UseCart';
import { UseWishlist } from './UseWishlist';
import { UseAdyen } from './UseAdyen';
import { UseAuthorizeNet } from './UseAuthorizeNet';
import { UseConfig } from './UseConfig';
import {
  getCards,
  deleteCard,
  setDefaultCreditCard,
  addCard,
  updateCard,
  getCard,
  getTransactionDetails,
  makePayment,
  makePaymentManualOrder,
} from '../../actions/authorize-net';
import { getConfig, getProjectSettings, getfacettedURLMappings } from '../../actions/config';
import { UseCategory } from './UseCategory';
import { getCategories } from '../../actions/category';
import {
  getBulbORRugModelProducts,
  getProduct,
  getAvailabilityMessage,
  getShopCollectionProducts,
  getProductRecommendationProducts,
  shareProductLink,
} from '../../actions/products';
import { UseProduct } from './useProduct';
import { UseFreshdesk } from './useFreshdesk';
import { createTicket } from '../../actions/freshdesk';
import {
  registerBusinessUnit,
  listBusinessUnits,
  approveDisApproveBusinessUnits,
  deleteBusinessUnit,
  applyDiscountPercentage,
  associateBusinessUnitToCustomer,
} from '../../actions/businessUnit';

import { UseBusinessUnit } from './UseBusinessUnit';

export interface FrontasticState {
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
  useAuthorizeNet: UseAuthorizeNet;
  useCategory: UseCategory;
  useConfig: UseConfig;
  useProduct: UseProduct;
  useFreshdesk: UseFreshdesk;
  useBusinessUnit: UseBusinessUnit;
}

export const getFrontasticState = (): FrontasticState => {
  return {
    useCart: {
      ...cartItems(),
      recentOrder: getRecentOrder(),
      addItem,
      addMultipleItems,
      updateCart,
      removeItem,
      updateItem,
      getShippingMethods,
      setShippingMethod,
      checkout,
      orderHistory,
      orderDetails,
      ordersByMonths,
      redeemDiscountCode,
      removeDiscountCode,
      addShoppingList,
      setSourceCode,
    },
    useAccount: {
      ...getAccount(),
      login,
      impersonateUser,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      addAddress,
      updateAddress,
      removeAddress,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
      signupNewsLetter,
      getCustomerAddresses,
      validateAddress,
      validateGuestCheckout,
      shippingLocations: getShippingLocations(),
      checkSubscription,
      updateSubscription,
      catalogRequest,
      catalogUnsubscribe,
      catalogUnsubscribeFreshDesk,
    },
    useWishlist: {
      ...getWishlist(),
      addToWishlist,
      removeLineItem,
      updateLineItem,
      shareWishlist,
    },
    useAdyen: {
      createSession,
    },
    useAuthorizeNet: {
      ...getCards(),
      deleteCard,
      setDefaultCreditCard,
      addCard,
      updateCard,
      getCard,
      getTransactionDetails,
      makePayment,
      makePaymentManualOrder,
    },
    useConfig: {
      ...getConfig(),
      getfacettedURLMappings,
      getProjectSettings,
    },
    useCategory: {
      getCategories,
    },
    useProduct: {
      getProduct,
      getBulbORRugModelProducts,
      getAvailabilityMessage,
      getShopCollectionProducts,
      getProductRecommendationProducts,
      shareProductLink,
    },
    useFreshdesk: {
      createTicket,
    },
    useBusinessUnit: {
      registerBusinessUnit,
      listBusinessUnits,
      approveDisApproveBusinessUnits,
      deleteBusinessUnit,
      applyDiscountPercentage,
      associateBusinessUnitToCustomer,
    },
  };
};
