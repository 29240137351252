import { JsonLd } from 'react-schemaorg';
import { Organization } from 'schema-dts';

const OrganizationRichText = () => {
  return (
    <>
      <JsonLd<Organization>
        item={{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Shades of Light',
          image: 'https://cdn.media.amplience.net/i/shadesoflight/logo-email',
          url: 'https://www.shadesoflight.com',
          logo: 'https://cdn.media.amplience.net/i/shadesoflight/logo-email',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '14001 Justice Rd., Midlothian',
            postalCode: '23113',
            addressRegion: 'VA',
            addressLocality: 'Virginia',
            addressCountry: 'USA',
          },
          sameAs: [
            'https://www.facebook.com/Shades-of-Light-115293741872',
            'https://twitter.com/ShadesofLightVA',
            'https://www.instagram.com/shadesoflight/',
            'https://www.pinterest.com/shadesoflight/',
          ],
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+18002626612',
              contactType: 'customer service',
            },
          ],
        }}
      />
    </>
  );
};

export default OrganizationRichText;
