import { Card, CardRequest, GetCardRequest, GetTransactionRequest } from '@Types/account/AuthorizeNetAccount';
import { TransactionRequest } from '@Types/payment';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import useSWR, { mutate } from 'swr';

export const getCards = () => {
  return useSWR<Card[]>('/action/authorize/getCards', fetchApiHub, revalidateOptions);
};

export const deleteCard = async (customerPaymentProfileId: string) => {
  const res = await fetchApiHub('/action/authorize/deleteCard', { method: 'POST' }, { customerPaymentProfileId });
  await mutate('/action/authorize/getCards');
  return res;
};

export const setDefaultCreditCard = async (customerPaymentProfileId: string) => {
  const res = await fetchApiHub(
    '/action/account/setDefaultCreditCard',
    { method: 'POST' },
    { customerPaymentProfileId },
  );

  await mutate('/action/authorize/getCards');
  return res;
};

export const addCard = async (card: CardRequest) => {
  const res = await fetchApiHub('/action/authorize/addCard', { method: 'POST' }, { ...card });

  await mutate('/action/authorize/getCards');
  return res;
};

export const updateCard = async (card: CardRequest) => {
  const res = await fetchApiHub('/action/authorize/updateCard', { method: 'POST' }, { ...card });
  await mutate('/action/authorize/getCards');
  return res;
};

export const getCard = async (card: GetCardRequest) => {
  const res = await fetchApiHub('/action/authorize/getCard', { method: 'POST' }, { ...card });
  return res;
};
export const getTransactionDetails = async (transaction: GetTransactionRequest) => {
  const res = await fetchApiHub('/action/authorize/getTransactionDetails', { method: 'POST' }, { ...transaction });
  return res;
};

export const makePayment = async (request: TransactionRequest) => {
  const res = await fetchApiHub('/action/authorize/payment', { method: 'POST' }, { ...request });
  await mutate('/action/cart/getCart', res);
  await mutate('/action/cart/recentOrder', res);
  await mutate('/action/cart/getOrders', res);
  return res;
};
export const makePaymentManualOrder = async (request: TransactionRequest, orderId: string) => {
  const res = await fetchApiHub(
    '/action/authorize/makePaymentManualOrder',
    { method: 'POST' },
    { ...request, orderId: orderId },
  );
  return res;
};
