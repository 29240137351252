import { routerURL } from 'helpers/utils/routerUrl';
import React, { useState } from 'react';

interface ContinueShoppingContextProps {
  currentPath: string;
  setCurrentPath: (currentPath: string) => void;
}

export const CurrentPathContext = React.createContext<ContinueShoppingContextProps>({
  currentPath: routerURL?.home,
  setCurrentPath: () => {},
});

export const ContinueShoppingContextProvider = (props) => {
  const [defaultPath, setDefaultPath] = useState<string>(routerURL?.home);
  return (
    <CurrentPathContext.Provider
      value={{
        currentPath: defaultPath,
        setCurrentPath: setDefaultPath,
      }}
    >
      {props.children}
    </CurrentPathContext.Provider>
  );
};
