import React, { useState } from 'react';

interface PageLoadContextProps {
  isPageLoading: boolean;
  setIsPageLoading: (isPageLoading: boolean) => void;
}

export const PageLoadContext = React.createContext<PageLoadContextProps>({
  isPageLoading: false,
  setIsPageLoading: () => {},
});

export const PageLoadContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <PageLoadContext.Provider
      value={{
        isPageLoading: isLoading,
        setIsPageLoading: setIsLoading,
      }}
    >
      {props.children}
    </PageLoadContext.Provider>
  );
};
