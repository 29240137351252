import { User } from '@Types/gtm-events/User';
import { Account } from '@Types/account/Account';

export const hydrateUserObj = (account: Account): User | undefined => {
  if (!account) return undefined;
  return {
    user_id: account?.accountId,
    name: account?.firstName + ' ' + account?.lastName,
    email: account?.email,
    emailHash: account?.emailHash,
    isTradeAccount: account?.isB2BApproved,
  };
};
