export const QUERY_KEY = '__algoliasearch_queryID';
export const PURCHASE_QUERY_KEY = '__algoliasearch_purchase_queryID';
const DEFAULT_VALUE = '';

export const setAlgoliaQueryID = (queryID) => {
  localStorage.setItem(QUERY_KEY, queryID ?? DEFAULT_VALUE);
};

export const getAlgoliaQueryID = () => {
  return localStorage.getItem(QUERY_KEY) ?? '';
};

export const clearAlgoliaQueryID = () => {
  localStorage.removeItem(QUERY_KEY);
};

export const setAlgoliaQueryIDForPurchase = (queryIDs) => {
  localStorage.setItem(PURCHASE_QUERY_KEY, JSON.stringify(queryIDs));
};

export const getAlgoliaQueryIDForPurchase = () => {
  const queryObj = localStorage.getItem(PURCHASE_QUERY_KEY);
  return queryObj ? JSON.parse(queryObj) : [];
};

export const clearAlgoliaQueryIDForPurchase = () => {
  localStorage.removeItem(PURCHASE_QUERY_KEY);
};
