import { User } from '@Types/gtm-events/User';
import { gtmTrackEvent, gtmTrackEventError } from 'helpers/utils/gtm-events/gtmTrackEvent';
import { hydrateUserObj } from 'helpers/utils/gtm-events/gtmHelpers';
import { StringHelpers } from 'helpers/stringHelpers';

export const gtmUserLogin = (account) => {
  const eventKey = 'user_login';
  try {
    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    gtmTrackEvent(eventKey, { user: userObject });

    return;
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmUserIdentify = (account) => {
  const eventKey = 'user_identify';
  try {
    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    gtmTrackEvent(eventKey, { user: userObject });

    return;
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmUserLogout = () => {
  const eventKey = 'user_logout';
  try {
    gtmTrackEvent(eventKey, { user: null });

    return;
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
