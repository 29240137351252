import { JsonLd } from 'react-schemaorg';
import { WebPage } from 'schema-dts';

const WebpageRichText = () => {
  return (
    <>
      <JsonLd<WebPage>
        item={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
        }}
      />
    </>
  );
};

export default WebpageRichText;
