import { JsonLd } from 'react-schemaorg';
import { Organization, SearchAction, WebSite } from 'schema-dts';

type SearchActionWithQueryInput = SearchAction & {
  'query-input': string;
};

const searchAction: SearchActionWithQueryInput = {
  '@type': 'SearchAction',
  target: `https://www.shadesoflight.com/search?q={search_term}`,
  'query-input': 'required name=search_term',
};

const WebsiteRichText = () => {
  return (
    <>
      <JsonLd<WebSite>
        item={{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: 'https://www.shadesoflight.com',
          potentialAction: searchAction,
        }}
      />
    </>
  );
};

export default WebsiteRichText;
